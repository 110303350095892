import React from "react";
import {Helmet} from "react-helmet";
import Layout from "components/Layout";
import {PropsWithTranslationSheet} from "../i18n/TranslationSheet";
import BlogPostsGrid from "components/BlogPostsGrid";

const BlogPage = (props) => {
    const {
        pageContext: {blogPosts, t}
    } = props as PropsWithTranslationSheet & any;

    return (
        <>
            <Helmet>
                <link rel={"canonical"} href={process.env.GATSBY_DOMAIN} />
            </Helmet>

            <Layout t={t} title={t.INDEX_TITLE} metaDescription={t.BLOG_META_DESCRIPTION}>
                <main>
                    <h1
                        id={"blog"}
                        className="my-8 text-xl font-bold text-gray-700 md:text-2xl text-center"
                    >
                        Blog
                    </h1>

                    <BlogPostsGrid blogPosts={blogPosts} />
                </main>
            </Layout>
        </>
    );
};

export default BlogPage;
